import React from 'react'
import Prices from '../../Components/Prices/Prices'

export default function Tarifs() {
    return (
        <>
            <Prices />
        </>
    )
}
